import { Component, Mixins } from 'vue-property-decorator';
import { ValidationMixin } from './ValidationMixin';

@Component({})
export class CompteValidationMixin extends Mixins(ValidationMixin) {

    // Vérifie si le type de profil est bien renseigné
    public typeProfilRules = [
        (v: number | any) => !!v || 'Le type de profil est obligatoire',
    ];

    // Type de compte Syndic/SDC.
    public typeCompteSyndicRules = [
        (v: string | any) => this.required(v) || 'Le type de compte Syndic/SDC est obligatoire',
    ];

    // Vérifie si le type de profil est bien renseigné
    public profilRules = [
        (v: number | any) => !!v || 'Le profil est obligatoire',
    ];

    // Vérifie si le champ civilité est bien renseigné
    public civiliteRules = [
        (v: string | any) => !!v || 'La civilité est obligatoire',
    ];

    // Vérifie si le champ Nom est bien renseigné
    public lastNameRules = [
        (v: string | any) => this.required(v) || 'Le nom est obligatoire',
    ];

    // Vérifie si le champ Prénom est bien renseigné
    public firstNameRules = [
        (v: string | any) => this.required(v) || 'Le prénom est obligatoire',
    ];

    // Vérifie si le champ fonction est bien renseigné
    public fonctionRules = [
        (v: string | any) => this.required(v) || 'La fonction est obligatoire',
    ];

    // Vérifie si le champ téléphone est bien renseigné
    public telephoneRules = [
        (v: string | any) => this.required(v) || 'Le téléphone est obligatoire',
        (v: string | any) => this.isPhoneNumber(v) || 'Le téléphone n\'est pas valide',
    ];

    // Vérifie si le champ mail est bien renseigné et valide
    public mailRules = [
        (v: string | any) => this.required(v) || 'Le mail est obligatoire',
        (v: string | any) => this.isEmail(v) || 'Le mail n\'est pas valide',
    ];

    // Vérifie si le champ userName est bien renseigné et valide
    public userNameRules = [
        (v: string | any) => this.required(v) || 'Le login ou N° GAIA est obligatoire',
        (v: string | any) => this.isUserName(v) || 'Le login ou N° GAIA n\'est pas valide, il ne doit contenir que des lettres et/ou chiffres et/ou caractères spéciaux valides(_-)',
    ];

    // Vérifie si le champ raison sociale est bien renseigné
    public raisonSocialeRules = [
        (v: string | any) => this.required(v) || 'La raison sociale est obligatoire',
    ];

    // Vérifie si le champ capital est bien renseigné
    public capitalRules = [
        (v: string | any) => this.required(v) || 'Le capital est obligatoire',
        (v: string | any) => this.isNumeric(v) || 'Le capital n\'est pas valide',
    ];

    // Vérifie si le champ capital est bien renseigné ou vide
    public capitalStrictRules = [
        (v: string | any) => this.required(v) || 'Le capital est obligatoire',
        (v: string | any) => this.isStrictlyGreaterThan(v, 0) || 'Le capital n\'est pas valide',
    ];

    // Vérifie si le champ forme juridique est bien renseigné
    public formeJuridiqueRules = [
        (v: string | any) => !!v || 'La forme juridique est obligatoire',
    ];

    // Vérifie si le champ SIRET est bien renseigné et valide
    public siretRules = [
        (v: string | any) => this.required(v) || 'Le siret est obligatoire',
        (v: string | any) => this.verifySiret(v) || 'Le siret n\'est pas valide',
    ];

    // Règles simplifiées sur le SIRET pour les utilisateurs internes.
    public siretRulesInterne = [
        (v: string | any) => this.required(v) || 'Le siret est obligatoire',
        (v: string | any) => v.length === 14 || 'Le siret doit faire 14 caractères'
    ];

    // Règles pour les sociétés bénéficiaires syndic/SDC.
    public siretRulesBeneficiaireSdc = [
        (v: string | any) => this.required(v) || 'Le siret est obligatoire',
        (v: string | any) => (this.verifyIdentification(v, 14) || v === '00000000000000') && v.length === 14 && this.isNumeric(v) || 'Le siret n\'est pas valide'
    ];

    // Vérifie si le champ lieu d'immatriculation est bien renseigné
    public lieuImmatriculationRules = [
        (v: string | any) => this.required(v) || 'Le lieu d\'immatriculation est obligatoire',
    ];

    // Vérifie si le type voie est bien renseigné
    public typeVoieRules = [
        (v: number | any) => !!v || 'Le type de voie est obligatoire',
    ];

    // Vérifie si le nom voie  est bien renseigné
    public nomVoieRules = [
        (v: string | any) => this.required(v) || 'Le nom de la voie est obligatoire',
        (v: string | any) => this.max(v, 1000) || 'Le nom de la voie est limité à 1000 caractères',
    ];

    // Vérifie si le champ code postal est bien renseigné et valide
    public codePostalRules = [
        (v: string | any) => this.required(v) || 'Le code postal est obligatoire',
        (v: string | any) => this.isCodePostal(v) || 'Le code postal n\'est pas valide',
    ];

    // Vérifie si le champ ville est bien renseigné
    public villeRules = [
        (v: string | any) => !!v || 'La ville est obligatoire',
    ];

    // Vérifie si le champ complément d'adresse est renseigné.
    public complementRules() {
        return [
            (v: string | any) => this.required(v) || 'En l\'absence du numéro de voie, veuillez saisir la référence cadastrale du site des travaux. ' +
                                                                        'Exemple : 000 (préfixe) AA (section) 000 (N° de parcelle)',
        ];
    }
}
