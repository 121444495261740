import { Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Mixin as VueMixinDecorator } from 'vue-mixin-decorator';
import { Utility } from '@/shared/helpers';
import { TemplateOperation } from '@/models';
import { ReferentielStoreMethods } from '@/store/modules/referentiel/ReferentielStore';
import { TypeValeurReferentielle, ValeurReferentielle } from '@/shared/models';

/**
 * Mixin regroupant les différentes méthodes utilisées par tous les composants.
 */
@VueMixinDecorator
export class CommonMixin extends Vue {

    /**
     * Action de récupération de valeurs référentielles.
     */
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;

    /**
     * Recuperer les template operation par secteur
     *
     * @param {string} secteur
     * @memberof CommonMixin
     */
    public recupererTemplateOperationsParSecteur(secteur: string, dateEngagementTravaux: string): Promise<TemplateOperation[]> {
        const filterCriteria = {
            ...{
                secteur,
                inclureInactif: false,
                inclureInVisibleUtilisateurExterne: true,
                dateEngagementTravaux
            },
        };
        return new Promise<TemplateOperation[]>((resolve, reject) => {
            this.$http.get(`/templateOperations/list`, { params: filterCriteria }).then((result) => {
                const { data: { data: responseData } } = result as ({ data: { data: TemplateOperation[], isError: boolean, messages: any[] } });
                let allTemplatesOperations = new Array<TemplateOperation>();
                if (!!responseData && responseData.length > 0) {
                    allTemplatesOperations = responseData;
                }
                resolve(allTemplatesOperations);
            }).catch((error: { response: string }) => {
                reject(error.response);
            });
        });
    }

    /**
     * Récupère la date de limite de fixation du prix.
     * @param dossierId L'identifiant du dossier.
     */
    public recupererDateLimiteFixationPrix(dossierId: undefined | number): Promise<string> {
        return this.recupererDateLieeAuDossier(dossierId, 'dateLimiteFixationPrix');
    }

    /**
     * Récupère la date du RAI pour l'utilisateur connecté.
     * @param dossierId L'identifiant du dossier.
     * */
    public recupererDateRai(dossierId: undefined | number): Promise<string> {
        return this.recupererDateLieeAuDossier(dossierId, 'dateRai');
    }

    /**
     * Récupère une date liée au dossier.
     * @param dossierId L'identifiant du dossier.
     * @param endpoint Le endpoint de la date sur le controller Dossier.
     */
    private recupererDateLieeAuDossier(dossierId: undefined | number, endpoint: string): Promise<string> {
        const criteria = {
            ...{
                dossierId
            }
        };
        return new Promise<string>((resolve, reject) => {
            this.$http.get(`/dossier/${endpoint}`, { params: criteria }).then((result) => {
                const { data: { data: responseData } } = result as ({ data: { data: string, isError: boolean, messages: any[] } });
                resolve(responseData);
            }).catch((error: { response: string }) => {
                reject(error.response);
            });
        });
    }

    /*
     * Converti une map vers un objet.
     */
    public MapToObj<T>(map: Map<string, T>): { [key: string]: T; } {
        const obj: { [key: string]: T; } = {};
        map.forEach((value: T, key) => obj[key] = value);
        return obj;
    }

    /**
     * Converti un objet vers une map.
     */
    public ObjToMap<T>(obj: { [key: string]: T; }): Map<string, T> {
        const mp = new Map<string, T>();
        Object.keys(obj).forEach((k) => mp.set(k, obj[k]));
        return mp;
    }

    /**
     * Converti un tableau vers une map.
     */
    public ArrayToMap<T>(tableau: T[], key: string): Map<string, T> {
        return new Map<string, T>(tableau.map((item) => [(item as any)[key], item] as [string, T]));
    }

    /**
     * Génère une suite aléatoire..
     */
    public rand(): string {
        return Utility.rand();
    }
}

