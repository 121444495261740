import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { DateHelper } from '@/shared/helpers';

/**
 * Mixin regroupant les différentes méthodes de validation pour les champs de formulaires.
 */
@Component
export class ValidationMixin extends Vue {

    /**
     * Vérifie l'identifiant.
     */
    public userNameOrEmailRules = [
        (v: string | any) => this.required(v) || 'L\'identifiant est obligatoire',
        (v: string | any) => this.isEmail(v) || 'L\'identifiant n\'est pas valide',
    ];

    /**
     * Vérifie le mot de passe.
     */
    public passwordRules = [
        (v: string | any) => this.required(v) || 'Le mot de passe est obligatoire',
        (v: string | any) => this.isPasswordValid(v) || 'Le mot de passe doit contenir 8 caractères dont 1 chiffre, 1 majuscule, 1 minuscule et 1 caractère spécial.',
    ];

    /**
     * Vérifie le mot de passe de confirmation.
     */
    public passwordConfirmRules = [
        (v: string | any) => this.required(v) || 'Le mot de passe de confirmation est obligatoire',
        (v: string | any) => this.isPasswordValid(v) || 'Le mot de passe doit contenir 8 caractères dont 1 chiffre, 1 majuscule, 1 minuscule et 1 caractère spécial.',
    ];

    /**
     * Vérifie que l'on a une valeur.
     * @param value : la valeur à vérifier.
     */
    public required(value: string | any): boolean {
        return !!value || value === 0;
    }

    /**
     * Le mot de passe doit contenir 8 caractères dont 1 chiffre, 1 majuscule, 1 minuscule et 1 caractère spécial.
     * @param value : la valeur à vérifier.
     */
    public isPasswordValid(value: string | any): boolean {
        const expReg = this.passwordRegExp;
        return expReg.test(value);
    }
    public get passwordRegExp(): RegExp {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!?@#$%^&_\/\\\-+.*])[A-Za-z\d!?@#$%^&_\/\\\-+.*]{8,}$/;
    }
    /**
     * Vérifie qu'une date est le premier du mois.
     * @param value La valeur à vérifier.
     */
    public premierDeMois(value: string | any): boolean {
        // On doit convertir la date parce qu'on saisie au format français.
        const date = this.convertieStringEnDate(value);
        return DateHelper.estPremierDuMois(date);
    }
    /**
     * Vérifie qu'une date est le dernier du mois.
     * @param value La valeur à vérifier.
     */
    public dernierDeMois(value: string | any): boolean {
        // On doit convertir la date parce qu'on saisie au format français.
        const date = this.convertieStringEnDate(value);
        return DateHelper.estDernierDuMois(date);
    }

    /**
     * Vérification du format des dates saisies.
     * @param value valeur saisie.
     */
    public isDateValide(value: string | any): boolean {
        if (!value) {
            return true;
        }

        const [jour, mois, annee] = value.split('/');
        const anneeInt = parseInt(annee, 10);
        const moisInt = parseInt(mois, 10);
        const jourInt = parseInt(jour, 10);
        return DateHelper.dateValide(anneeInt, moisInt, jourInt);
    }

    /**
     * Vérification du format des dates saisies du format (Mois/Année).
     * @param value valeur saisie.
     * @returns: Vrai si la date est valide, Faux dans le cas échéant.
     */
    public isDateValideMoisAnnee(value: string | any): boolean {
        if (!value) {
            return true;
        }

        const [mois, annee] = value.split('/');
        const anneeInt = parseInt(annee, 10);
        const moisInt = parseInt(mois, 10);
        return DateHelper.dateValide(anneeInt, moisInt, 1);
    }

    /**
     * Vérifie qu'une date n'est pas dans le passé.
     * Ne tient pas compte des heures.
     * @param value valeur saisie.
     * @returns Vrai si la date n'est pas dans le passé.
     */
    public isDateNonPasse(value: string | any): boolean {
        if (!value) {
            return true;
        }
        const [jour, mois, annee] = value.split('/');
        const dateSaisie = DateHelper.creerDate(annee, mois, jour);
        const dateDuJour = new Date().toISOString().substr(0, 10);
        return DateHelper.premiereSuperieurASeconde(dateSaisie, dateDuJour, true);
    }

    /**
     * Vérifie que la valeur est numérique et positive.
     * @param value : la valeur à vérifier.
     */
    public isNumeric(value: string | any, withComa: boolean = false): boolean {
        if (withComa && value) {
            value = value.toString().replace(',', '.');
        }
        return !isNaN(parseFloat(value)) && isFinite(value) && parseFloat(value) >= 0;
    }

    /**
     * Vérifie que la valeur est numérique et positive et inférieure ou égale à la valeur maximale.
     * @param value : la valeur à vérifier.
     * @param maxValue : La valeur maximale.
     */
    public isLessThanOrEqualTo(value: string | any, maxValue: number): boolean {
        return this.isNumeric(value) && parseFloat(value) <= maxValue;
    }

    /**
     * Vérifie que la valeur est numérique est positive et supérieure à la valeur minimale.
     * @param value : la valeur à vérifier.
     * @param minValue : La valeur minimale.
     */
    public isStrictlyGreaterThan(value: string | any, minValue: number): boolean {
        return this.isNumeric(value) && parseFloat(value) > minValue;
    }

    /**
     * Vérifie que la valeur est entière.
     * @param value : la valeur à vérifier.
     */
    public isInteger(value: string | any): boolean {
        const expReg = /^[0-9]+$/;
        return expReg.test(value);
    }

    /**
     * Vérifie que la chaîne de caractère respecte la longueur autorisée.
     * @param value @type {string} : la valeur à vérifier.
     * @param length @type {number}: la longueur à vérifier.
     */
    public equal(value: string | any, length: number): boolean {
        return this.required(value) && value.length === length;
    }

    /**
     * Vérifie que la valeur n'excède pas la longueur maximal.
     * @param value : la valeur à vérifier.
     * @param length : la longueur maximal.
     */
    public max(value: string | any, length: number): boolean {
        return this.required(value) && value.length <= length;
    }

    /**
     * Vérifie que la valeur n'est pas inférieur à la longueur minimal.
     * @param value : la valeur à vérifier.
     * @param length : la longueur minimal.
     */
    public min(value: string | any, length: number): boolean {
        return this.required(value) && value.length >= length;
    }

    /**
     * Vérifie que l'adresse email est valide.
     * @param value : la valeur à vérifier.
     */
    public isEmail(value: string | any): boolean {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    }

    /**
     * Vérifie que le username ou login est valide.
     * @param value : la valeur à vérifier.
     */
    public isUserName(value: string | any): boolean {
        const regexPattern = /^[A-Za-z0-9]+(?:[_-][A-Za-z0-9]+)*$/;
        return regexPattern.test(value);
    }

    /**
     * Vérifie que le numéro de téléphone est valide.
     * @param value : la valeur à vérifier.
     */
    public isPhoneNumber(value: string | any): boolean {
        const forbiddenValue: Array<string> = ['0123456789', '0000000000'];
        if (value !== null && typeof value !== 'undefined') {
            const trimValue: string = value.trim();
            return this.isValidNumeric(value) && trimValue.length === 10 && !forbiddenValue.includes(trimValue) && trimValue.startsWith('0');
        } else {
            return false;
        }
    }

    /**
     * Vérifie que le code postal est valide.
     * @param value : la valeur à vérifier.
     */
    public isCodePostal(value: string | any): boolean {
        const expReg = /^[0-9]{5}$/;
        return expReg.test(value);
    }

    private isValidNumeric(value: string | any): boolean {
        if (value !== null && typeof value !== 'undefined') {
            const trimValue: string = value.trim();
            return this.isNumeric(trimValue);
        } else {
            return false;
        }
    }

    /**
     * Vérifie que le SIRET est valide.
     * @param numero : la valeur à vérifier.
     * https://github.com/steevelefort/siret
     */
    public verifySiret(numero: string | any): boolean {
        return !this.required(numero) || this.verifyIdentification(numero, 14) && numero.length === 14 && this.isNumeric(numero);
    }

    /**
     * Vérifie que le SIREN est valide.
     * @param numero : la valeur à vérifier.
     * https://github.com/steevelefort/siret
     */
    public verifySiren(numero: string): boolean {
        return this.required(numero) && this.verifyIdentification(numero, 9);
    }

    /**
     * Traduit la clé de ressource texte passée en paramètre.
     * @param key La clé à traduire.
     * @returns La traduction.
     */
    public translate(key: string): string {
        return this.$root.$i18n.t(key).toString();
    }

    public verifyPassword(value: string): boolean {
        const expReg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&_/\\\-+.*])[0-9a-zA-Z!@#$%^&_/\\\-+.*0-9]{8,}$/;
        return expReg.test(value);
    }

    /**
     * Convertie une date au format DD/MM/YYYY à un objet Date JS.
     */
    public convertieStringEnDate(value: string): Date {
        const [jour, mois, annee] = value.split('/');
        return new Date(+annee, +mois - 1, +jour);
    }

    /**
     * Vérifie qu'un numéro d'identification est valide en fonction de sa longueur.
     * @param numero : la valeur à vérifier.
     * @param size : la longueur de l'identifiant à vérifier.
     */
    public verifyIdentification(numero: string, size: number): boolean {
        const bit = 1;
        let sum = 0;
        let val = 0;

        for (let i = size - 1; i >= 0; i--) {
            const step = (numero.charCodeAt(i) - 48) * (val + bit);
            sum += (step > 9) ? step - 9 : step;
            val = bit - val;
        }

        return !!sum && sum % 10 === 0;
    }
}
